<template>
  <v-container fluid class="my-4 google-font">
    <v-layout wrap align-center justify-center row fill-height>
      <v-flex xs12 md10 class="py-2 card">
        <v-card>
          <v-card-title primary-title class="px-5 pt-5">
            Sample BugSeq Outputs
          </v-card-title>
          <v-card-text class="px-5 pt-2">
            Below you will find example reports from BugSeq. As methods change, we recommend you <a href="/resources/support">reach out</a> for the most up to date information.
            <div class="mt-5">
              <div>
                It all starts with our simple submission form:
              </div>
              <v-btn
                color="primary"
                class="text-capitalize ml-0 pa-4"
                href="/demo/img/upload-form.png"
                target="_blank"
              >
                <v-icon class="mr-3">cloud_upload</v-icon>
                Upload Form
              </v-btn>
            </div>
            <div v-for="section in demoSections" :key="section.name" class="mt-5">
              <div class="font-weight-bold mb-2">
                {{ section.name }}
              </div>
              <div>
                <v-btn
                  v-for="report in section.reports"
                  :key="report.name"
                  color="primary"
                  class="text-capitalize mr-4 ml-0 pa-4"
                  :href="report.link"
                  target="_blank"
                >
                  <v-icon class="mr-3">{{ report.icon }}</v-icon>
                  {{ report.name }}
                </v-btn>
              </div>
              <div v-if="section.otherReports.length > 0" class="mt-3">
                Other outputs that you can expect:
                <ul class="mt-2">
                  <li v-for="report in section.otherReports" :key="report">{{ report }}</li>
                </ul>
              </div>
            </div>
            <div class="font-weight-bold mt-5 mb-2">
              Insights
            </div>
            <div>
              Query across all historical data to answer questions like:
              <ul>
                <li>When was the last a specific isolate was processed?</li>
                <li>For all samples of a specific type, what resistance genes have been found?</li>
                <li>What chromosomes have you seen a particular plasmid on?</li>
                <li>Which samples are in a given outbreak cluster?</li>
              </ul>
              <div class="mt-2">
                <v-btn
                  class="text-capitalize ml-0 pa-4"
                  color="primary"
                  href="/demo/img/insights-demo.png"
                  target="_blank"
                ><v-icon class="mr-3">vertical_split</v-icon>Insights</v-btn>
              </div>
            </div>
            <div class="font-weight-bold mt-5 mb-2">
              Labs
            </div>
            <div>
              Within a lab, many people are involved between collecting a sample and taking action. With Labs on BugSeq, you can:
              <ul>
                <li>Organize lab members into sections or divisions that match your physical lab</li>
                <li>Share reports with other lab members</li>
                <li>Enforce access controls to control who has access to data</li>
                <li>Manage notifications so that lab members can be notified when data is available</li>
                <li>Aggregate samples over time and visualize outbreaks as more samples are added</li>
              </ul>
              <div class="mt-2">
                <v-btn
                  class="text-capitalize ml-0 pa-4"
                  color="primary"
                  href="/demo/img/labs-demo.png"
                  target="_blank"
                ><v-icon class="mr-3">groups</v-icon>Labs</v-btn>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
  metaInfo() {
    return {
      title: "Demo Reports",
    };
  },
})
export default class Demo extends Vue {
  private demoSections = [
    {
      name: "Isolates (Bacterial, Fungal)",
      reports: [
        {
          name: "Summary Report",
          link: "/demo/hospital-acquired-infections-summary.html",
          icon: "table_view",
        },
        {
          name: "Sample Report",
          link: "/demo/hospital-acquired-infections-sample.html",
          icon: "troubleshoot",
        },
      ],
      otherReports: [
        "Assemblies (fasta)",
      ]
    },
    {
      name: "Metagenomics",
      reports: [
        {
          name: "Summary Report",
          link: "/demo/clinical-metagenomics-summary.html",
          icon: "table_view",
        },
        {
          name: "Sample Report",
          link: "/demo/clinical-metagenomics-sample.html",
          icon: "troubleshoot",
        },
      ],
      otherReports: [
        "Assemblies (fasta)",
        "Krona plots and CSV tables for metagenomic classification",
      ]
    },
    {
      name: "Outbreak Analysis",
      reports: [
        {
          name: "Cluster Visualization",
          link: "/demo/outbreak-analysis.html",
          icon: "account_tree",
        },
        {
          name: "Distance Matrix",
          link: "/demo/outbreak-analysis.html",
          icon: "open_in_full",
        },
      ],
      otherReports: [
        "All found plasmids and AMR markers are queryable under Insights",
      ],
    },
    {
      name: "16S",
      reports: [
        {
          name: "Summary Report",
          link: "https://static-aa.bugseq.com/demo/16S-2024-12-02-summary.html",
          icon: "table_view",
        },
        {
          name: "Sample Report",
          link: "https://static-aa.bugseq.com/demo/16S-2024-12-02-sample.html",
          icon: "troubleshoot",
        },
        {
          name: "Classification",
          link: "https://static-aa.bugseq.com/demo/16S-2024-12-02-krona.html",
          icon: "donut_small",
        },
      ],
      otherReports: [
        "Summary report with quality control data",
        "Krona plots and CSV tables for classification",
      ]
    }
  ]
}
</script>

<style scoped>
</style>
