<template>
  <v-app>
    <v-container fluid class="my-4 google-font">
      <v-layout align-center justify-center row>
        <v-flex xs12 md8 xl6>
          <v-card class="pa-4">
            <div class="home-section-title mb-4">Publications</div>
            <div class="my-4">
              BugSeq is proud to support our users who publish innovative work.
              If you are interested in using BugSeq for a publication, please
              contact
              <a href="mailto:support@bugseq.com">support@bugseq.com</a>.
            </div>
            <div class="mb-3 mt-5">
              <b><v-icon class="mr-2" color="black">push_pin</v-icon>Pinned</b>
            </div>
            <Publication
              v-for="publication in publications.filter((p) => p.pinned)"
              :key="publication.text"
              :publication="publication"
            />
            <div v-for="year in getYears()">
              <div class="mb-3 mt-5">
                <b>{{ year }}</b>
              </div>
              <Publication
                v-for="publication in getPublications(year)"
                :key="publication.text"
                :publication="publication"
              />
            </div>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Publication from "@bugseq-site/www/src/components/publications/Publication.vue";

@Component({
  metaInfo() {
    return {
      title: "Publications",
    };
  },
  components: {
    Publication,
  },
})
export default class PublicationsPage extends Vue {
  public publications = [
  {
      pinned: false,
      year: 2025,
      text:
        'Maria et al. <a href="https://doi.org/10.1016/j.fm.2024.104649" target="_blank">Microbial composition and dynamics in environmental samples from a ready-to-eat food production facility with a long-term colonisation of Listeria monocytogenes.</a> <i>Food Microbiology</i> (2025).',
    },
    {
      pinned: true,
      year: 2024,
      text:
        'Gauthier et al. <a href="https://doi.org/10.1093/infdis/jiae226" target="_blank">Validation of an automated, end-to-end metagenomic sequencing assay for agnostic detection of respiratory viruses.</a> <i>The Journal of Infectious Diseases</i> (2024).',
    },
    {
      pinned: true,
      year: 2024,
      text:
        'Stefanovic et al. <a href="https://doi.org/10.1093/cid/ciae575" target="_blank">Increased severity of multidrug-resistant <i>Shigella sonnei</i> infections in people experiencing homelessness.</a> <i>Clinical Infectious Diseases</i> (2024).',
    },
    {
      pinned: false,
      year: 2024,
      text:
        'Lo et al. <a href="https://doi.org/10.1099/jmm.0.001881" target="_blank">Evaluating the accuracy of the MBT Lipid Xtract Kit for assessing colistin resistance in comparison to broth microdilution.</a> <i>Journal of Medical Microbiology</i> (2024).',
    },
    {
      pinned: true,
      year: 2024,
      text:
        'Chorlton SD. <a href="https://doi.org/10.3389/fbinf.2024.1278228" target="_blank">Ten common issues with reference sequence databases and how to mitigate them.</a> <i>Frontiers in Bioinformatics</i> (2024).',
    },
    {
      pinned: false,
      year: 2024,
      text:
        'Song et al. <a href="https://doi.org/10.1101/2024.10.15.618384" target="_blank">DEMINERS enables clinical metagenomics and comparative transcriptomic analysis by increasing throughput and accuracy of nanopore direct RNA sequencing.</a> <i>bioRxiv</i> (2024).',
    },
    {
      pinned: false,
      year: 2024,
      text:
        'Pham et al. <a href="https://doi.org/10.1128/aem.01892-24" target="_blank">Does strain-level persistence of lactobacilli in long-term back-slopped sourdoughs inform on domestication of food-fermenting lactic acid bacteria?</a> <i>Applied and Environmental Microbiology</i> (2024).',
    },
    {
      pinned: false,
      year: 2024,
      text:
        'Agustinho et al. <a href="https://doi.org/10.1038/s41592-024-02262-1" target="_blank">Unveiling microbial diversity: harnessing long-read sequencing technology.</a> <i>Nature Methods</i> (2024).',
    },
    {
      pinned: false,
      year: 2024,
      text:
        'Kim, Pongpanich & Porntaveetus. <a href="https://doi.org/10.1038/s41592-024-02262-1" target="_blank">Unraveling metagenomics through long-read sequencing: a comprehensive review.</a> <i>Journal of Translational Medicine</i> (2024).',
    },
    {
      pinned: false,
      year: 2024,
      text:
        'Khdhiri et al. <a href="https://doi.org/10.1186/s12859-024-05913-4" target="_blank">refMLST: reference-based multilocus sequence typing enables universal bacterial typing.</a> <i>BMC Bioinformatics</i> (2024).',
    },
    {
      pinned: true,
      year: 2024,
      text:
        'Bertolo, Valido & Stoyanov. <a href="https://doi.org/10.1186/s12866-024-03208-5" target="_blank">Optimized bacterial community characterization through full-length 16S rRNA gene sequencing utilizing MinION nanopore technology.</a> <i>BMC Microbiology</i> (2024).',
    },
    {
      pinned: false,
      year: 2024,
      text:
        'Thu Hein et al. <a href="https://doi.org/10.3389/fvets.2024.1362011" target="_blank">Metagenomic insights into isolable bacterial communities and antimicrobial resistance in airborne dust from pig farms.</a> <i>Front. Vet. Sci.</i> (2024).',
    },
    {
      pinned: false,
      year: 2024,
      text:
        'Stoeck et al. <a href="https://doi.org/10.3897/mbmg.8.121817" target="_blank">Nanopore duplex sequencing as an alternative to Illumina MiSeq sequencing for eDNA-based biomonitoring of coastal aquaculture impacts.</a> <i>Metabarcoding and Metagenomic</i> (2024).',
    },
    {
      pinned: false,
      year: 2024,
      text:
        'Agustinho et al. <a href="https://doi.org/10.1038/s41592-024-02262-1" target="_blank">Unveiling microbial diversity: harnessing long-read sequencing technology.</a> <i>Nature Methods</i> (2024).',
    },
    {
      pinned: false,
      year: 2024,
      text:
        'Giger et al. <a href="https://doi.org/10.21203/rs.3.rs-4046525/v1" target="_blank">Molecular Evidence of high prevalence of Wolbachia Species in Wild-Caught <i>Aedes albopictus</i> and <i>Aedes aegypti</i> mosquitoes on Bioko Island, Equatorial Guinea.</a> <i>Research Square</i> (2024).',
    },
    {
      pinned: false,
      year: 2024,
      text:
        'Patz et al. <a href="https://doi.org/10.1101/2024.02.17.580828" target="_blank">mgPGPT: Metagenomic analysis of plant growth-promoting traits.</a> <i>bioRxiv</i> (2024).',
    },
    {
      pinned: false,
      year: 2024,
      text:
        'Kim et al. <a href="https://doi.org/10.1101/2024.03.10.24303884" target="_blank">Applying Fast Healthcare Interoperability Resources (FHIR) for Pathogen Genomics at the Point of Care.</a> <i>medRxiv</i> (2024).',
    },
    {
      pinned: false,
      year: 2024,
      text:
        'Kim, Pongpanich & Porntaveetus. <a href="https://doi.org/10.1186/s12967-024-04917-1" target="_blank">Unraveling metagenomics through long-read sequencing: a comprehensive review.</a> <i>J Transl Med</i> (2024).',
    },
    {
      pinned: false,
      year: 2023,
      text:
        'Urban et al. <a href="https://doi.org/10.21203/rs.3.rs-3620416/v1" target="_blank">Detection of hidden antibiotic resistance through real-time genomics.</a> <i>Research Square</i> (2023).',
    },
    {
      pinned: false,
      year: 2023,
      text:
        'Valerio, Twort & Duplouy. <a href="https://doi.org/10.1007/978-1-0716-3553-7_16" target="_blank">Screening Host Genomic Data for Wolbachia Infections.</a> <i>Methods in Molecular Biology</i> (2023).',
    },
    {
      pinned: false,
      year: 2023,
      text:
        'Zhang et al. <a href="https://doi.org/10.1128/aem.00605-23" target="_blank">The newest Oxford Nanopore R10.4.1 full-length 16S rRNA sequencing enables the accurate resolution of species-level microbial community profiling.</a> <i>Applied and Environmental Microbiology</i> (2023).',
    },
    {
      pinned: false,
      year: 2023,
      text:
        'Theologidis et al. <a href="https://doi.org/10.3390/jof9111119" target="_blank">Nanopore-Sequencing Metabarcoding for Identification of Phytopathogenic and Endophytic Fungi in Olive (<i>Olea europaea</i>) Twigs.</a> <i>Journal of Fungi</i> (2023).',
    },
    {
      pinned: false,
      year: 2023,
      text:
        'Ritchie et al. <a href="https://doi.org/10.1093/jac/dkad346" target="_blank">WGS of a cluster of MDR <i>Shigella sonnei</i> utilizing Oxford Nanopore R10.4.1 long-read sequencing.</a> <i>Journal of Antimicrobial Chemotherapy</i> (2023).',
    },
    {
      pinned: false,
      year: 2023,
      text:
        'Pavan et al. <a href="https://doi.org/10.4103/aian.aian_460_23" target="_blank">Dysbiosis of the Beneficial Gut Bacteria in Patients with Parkinson\'s Disease from India.</a> <i>Annals of Indian Academy of Neurology</i> (2023).',
    },
    {
      pinned: false,
      year: 2023,
      text:
        'Bloomfield et al. <a href="https://doi.org/10.1016/j.pathol.2023.07.014" target="_blank">Oxford nanopore next generation sequencing in a front-line clinical microbiology laboratory without on-site bioinformaticians.</a> <i>Pathology</i> (2023).',
    },
    {
      pinned: false,
      year: 2023,
      text:
        'Stefanovic et al. <a href="https://doi.org/10.3201/eid2908.230323" target="_blank">Multidrug-Resistant <i>Shigella sonnei</i> Bacteremia among Persons Experiencing Homelessness, Vancouver, British Columbia, Canada.</a> <i>Emerging Infectious Diseases</i> (2023).',
    },
    {
      pinned: false,
      year: 2023,
      text:
        'Wettstein et al. <a href="https://doi.org/10.1038/s41598-023-39519-2" target="_blank">Understanding the impact of spinal cord injury on the microbiota of healthy skin and pressure injuries.</a> <i>Scientific Reports</i> (2023).',
    },
    {
      pinned: false,
      year: 2023,
      text:
        'Zheng et al. <a href="https://doi.org/10.1002/mco2.316" target="_blank">Nanopore sequencing technology and its applications.</a> <i>MedComm</i> (2023).',
    },
    {
      pinned: false,
      year: 2023,
      text:
        'Gauthier et al. <a href="/publications/Gauthier_ECCMID_2023_poster.pdf" target="_blank">Optimization and test performance of metagenomic sequencing for respiratory viral pathogens.</a> Presented at ECCMID (2023).',
    },
    {
      pinned: false,
      year: 2023,
      text:
        'Sharma et al. <a href="https://doi.org/10.1089/hs.2022.0160" target="_blank">Threat Net: A Metagenomic Surveillance Network for Biothreat Detection and Early Warning.</a> <i>Health Security</i> (2023).',
    },
    {
      pinned: false,
      year: 2023,
      text:
        'Nasko et al. <a href="https://www.pacb.com/wp-content/uploads/2023-ASM-Microbe-Profiling-poster.pdf" target="_blank">Taxonomic classification and profiling of long-read shotgun metagenomics datasets: best practices and new pipelines.</a> Presented at ASM Microbe (2023).',
    },
    {
      pinned: false,
      year: 2023,
      text:
        'Al-Taee. <a href="https://doi.org/10.5772/intechopen.107899" target="_blank">Molecular Detection and Identification of Candida.</a> <i>Candida and Candidiasis</i> (2023).',
    },
    {
      pinned: false,
      year: 2023,
      text:
        'Ferrillo et al. <a href="https://doi.org/10.1007/978-1-0716-3151-5_19" target="_blank">Long-Read Metagenomics and CAZyme Discovery.</a> In Carbohydrate-Protein Interactions: Methods and Protocols (eds. Abbott, D. W. & Zandberg, W. F.) 253-284 (Springer US, 2023).',
    },
    {
      pinned: false,
      year: 2023,
      text:
        'Marchukov et al. <a href="https://doi.org/10.3389/fgene.2023.1184473" target="_blank">Benchmarking microbial DNA enrichment protocols from human intestinal biopsies.</a> <i>Frontiers in Genetics</i> (2023).',
    },
    {
      pinned: false,
      year: 2023,
      text:
        'Gauthier et al. <a href="https://doi.org/10.1128/cmr.00119-22" target="_blank">Agnostic Sequencing for Detection of Viral Pathogens.</a> <i>Clinical Microbiology Reviews</i> (2023).',
    },
    {
      pinned: false,
      year: 2023,
      text:
        'Pedroso-Roussado et al. <a href="https://doi.org/10.12688/openreseurope.15485.1" target="_blank">Nanopore sequencing of DNA barcodes to unveil the diversity of fungal mock communities.</a> <i>Open Research Europe</i> (2023).',
    },
    {
      pinned: false,
      year: 2023,
      text:
        'Ritchie et al. <a href="https://doi.org/10.1128/spectrum.04777-22" target="_blank">No Isolate, No Problem: Using a Novel Insertion Sequence PCR to Link Rats to Human Shigellosis Cases in an Underserved Urban Community.</a> <i>Microbiology Spectrum</i> (2023).',
    },
    {
      pinned: false,
      year: 2022,
      text:
        'Portik, Brown & Pierce-Ward. <a href="https://doi.org/10.1186/s12859-022-05103-0" target="_blank">Evaluation of taxonomic classification and profiling methods for long-read shotgun metagenomic sequencing datasets.</a> <i>BMC Bioinformatics</i> (2022).',
    },
    {
      pinned: false,
      year: 2022,
      text:
        'Gauthier et al. <a href="https://doi.org/10.1371/journal.pone.0275815" target="_blank">Alterations in the nasopharyngeal microbiome associated with SARS-CoV-2 infection status and disease severity.</a> <i>PLOS ONE</i> (2022).',
    },
    {
      pinned: false,
      year: 2022,
      text:
        'Chandrakumar & Chorlton. <a href="/publications/refMLST_BugSeq_ASM_NGS_2022.pdf" target="_blank">Reference-based Multilocus Sequence Typing Enables Universal, Decentralized Bacterial Typing.</a> Presented at ASM NGS (2022).',
    },
    {
      pinned: false,
      year: 2022,
      text:
        'Penrice-Randal et al. <a href="https://doi.org/10.3390/insects13100906" target="_blank">New Cell Lines Derived from Laboratory Colony Triatoma infestans and Rhodnius prolixus, Vectors of Trypanosoma cruzi, Do Not Harbour Triatoma Virus.</a> <i>Insects</i> (2022).',
    },
    {
      pinned: false,
      year: 2022,
      text:
        'Cunningham-Oakes et al. <a href="https://doi.org/10.1101/2022.10.03.510536" target="_blank">Emerging variants of canine enteric coronavirus associated with seasonal outbreaks of severe canine gastroenteric disease.</a> <i>bioRxiv</i> (2022).',
    },
    {
      pinned: false,
      year: 2022,
      text:
        'Torii et al. <a href="https://doi.org/10.1186/s12879-022-07537-6" target="_blank">Detection of antiviral drug resistance in patients with congenital cytomegalovirus infection using long-read sequencing: a retrospective observational study.</a> <i>BMC Infectious Diseases</i> (2022).',
    },
    {
      pinned: false,
      year: 2022,
      text:
        'Hoang et al. <a href="https://doi.org/10.3389/fmicb.2021.708550" target="_blank">Long-reads based metagenomics in clinical diagnosis with a special focus on fungal infections.</a> <i>Frontiers in Microbiology</i> (2022).',
    },
    {
      pinned: false,
      year: 2022,
      text:
        'Perry et al. <a href="https://doi.org/10.3389/fenvs.2021.773991" target="_blank">Challenges to Implementing Environmental-DNA Monitoring in Namibia.</a> <i>Frontiers in Environmental Science</i> (2022).',
    },
    {
      pinned: false,
      year: 2022,
      text:
        'Marshall et al. <a href="/publications/JMC_APHL_2022.pdf" target="_blank">LIMS Lite: A Public Health Informatics Ecosystem for Implementing Sequencing Workflows, Bioinformatics Analyses and Data Sharing in Public Health Laboratories.</a> Presented at APHL 2022.',
    },
    {
      pinned: false,
      year: 2022,
      text:
        'Chandrakumar et al. <a href="https://doi.org/10.1038/s42003-022-03114-4" target="_blank">BugSplit enables genome-resolved metagenomics through highly accurate taxonomic binning of metagenomic assemblies.</a> <i>Communications Biology</i> (2022).',
    },
    {
      pinned: false,
      year: 2021,
      text:
        'Gauthier et al. <a href="https://doi.org/10.1371/journal.pone.0259712" target="_blank">Nanopore Metagenomic Sequencing for Detection and Characterization of SARS-CoV-2 in Clinical Samples.</a> <i>PLOS ONE</i> (2021).',
    },
    {
      pinned: false,
      year: 2021,
      text:
        'Goelz et al. <a href="https://doi.org/10.3390/cells10113226" target="_blank">Next- and Third-Generation Sequencing Outperforms Culture-Based Methods in the Diagnosis of Ascitic Fluid Bacterial Infections of ICU Patients.</a> <i>Cells</i> (2021).',
    },
    {
      pinned: false,
      year: 2021,
      text:
        'Wagner et al. <a href="https://doi.org/10.1128/AEM.01774-21" target="_blank">Surveillance of Listeria monocytogenes: Early detection, population dynamics and quasimetagenomic sequencing during selective enrichment.</a> <i>Applied and Environmental Microbiology</i> (2021).',
    },
    {
      pinned: false,
      year: 2021,
      text:
        'Chorlton et al. <a href="https://doi.org/10.1016/j.antiviral.2021.105123" target="_blank">Next-generation sequencing for cytomegalovirus antiviral resistance genotyping in a clinical virology laboratory.</a> <i>Antiviral Research</i> (2021).',
    },
    {
      pinned: false,
      year: 2021,
      text:
        'Jung & Chorlton. <a href="https://doi.org/10.1101/2021.03.16.434153" target="_blank">BugSeq 16S: NanoCLUST with Improved Consensus Sequence Classification.</a> <i>bioRxiv</i> (2021).',
    },
    {
      pinned: false,
      year: 2021,
      text:
        'Matic et al. <a href="https://doi.org/10.3201/eid2706.210532" target="_blank">Rapid detection of SARS-CoV-2 variants of concern, including B.1.1.28/P.1, in British Columbia, Canada.</a> <i>Emerging Infectious Diseases</i> (2021).',
    },
    {
      pinned: false,
      year: 2021,
      text:
        'Moragues-Solanas, Scotti & O’Grady. <a href="https://doi.org/10.1080/14737159.2021.1906652" target="_blank">Rapid metagenomics for diagnosis of bloodstream and respiratory tract nosocomial infections: current status and future prospects.</a> <i>Expert Review of Molecular Diagnostics</i> (2021).',
    },
    {
      pinned: false,
      year: 2021,
      text:
        'Koppad, B, Gkoutos & Acharjee. <a href="https://doi.org/10.1177/11779322211035921" target="_blank">Cloud Computing Enabled Big Multi-Omics Data Analytics.</a> <i>Bioinformatics and Biology Insights</i> (2021).',
    },
    {
      pinned: false,
      year: 2021,
      text:
        "Lapointe et al. An Inter-Laboratory Genomic Cross-Validation of a COVID-19 Outbreak in a Long-Term Care Facility. Presented at Canadian Association for HIV Research Conference on HIV/AIDS (2021).",
    },
    {
      pinned: false,
      year: 2021,
      text:
        'Fan, Huang & Chorlton. <a href="https://doi.org/10.1186/s12859-021-04089-5" target="_blank">BugSeq: a highly accurate cloud platform for long-read metagenomic analyses.</a> <i>BMC Bioinformatics</i> (2021).',
    },
  ];

  public getYears() {
    return Array.from(
      new Set(this.publications.filter((p) => !p.pinned).map((p) => p.year)),
    )
      .sort()
      .reverse();
  }

  public getPublications(year) {
    return this.publications
      .filter((p) => !p.pinned)
      .filter((p) => p.year === year);
  }
}
</script>
